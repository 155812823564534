<script>
const firmName = "和風聯合法律事務所";
export default {
  firmName
}
</script>

<style scoped>

</style>
